export const getPaginationRange = (curr: number, last: number) => {
  const delta = 1;
  const initialRangeForEdges = 4;
  let left = 0;
  let right = last;

  if (curr < initialRangeForEdges && last > initialRangeForEdges) {
    left = 0;
    right = initialRangeForEdges;
  } else if (curr > last - initialRangeForEdges && last > initialRangeForEdges) {
    left = last - initialRangeForEdges;
    right = last;
  } else {
    left = curr - delta;
    right = curr + delta;
  }

  const range: number[] = [];

  for (let i = 0; i <= last; i++) {
    if (i === 0 || i === last || (i >= left && i <= right)) {
      range.push(i);
    }
  }

  let rangeWithDots: (string | number)[] = [range[0]];
  for (let i = 1; i < range.length; i++) {
    if (range[i] - range[i - 1] !== 1) {
      rangeWithDots = [...rangeWithDots, "..."];
    }
    rangeWithDots = [...rangeWithDots, range[i]];
  }
  return rangeWithDots;
};
