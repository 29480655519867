/** @jsx jsx */
import React, { FC } from "react";
import { jsx, Flex, Text } from "theme-ui";
import { InputProps, LabelProps, BeforeAndAfterProps } from "./types";
import { HEIGHT, VARIANT } from "./index";

const getBorderStyles = ({ error, warning, success, variant, isFocused }) => {
  if (isFocused) {
    return { borderColor: "slategray" };
  }

  if (error) {
    return {
      borderColor: "crimson",
    };
  }

  if (warning) {
    return {
      borderColor: "goldenrod",
    };
  }

  if (success) {
    return {
      borderColor: "mediumseagreen",
    };
  }

  if (variant === VARIANT.plain) {
    return {
      borderColor: "whitesmoke",
    };
  }

  return {
    borderColor: "border",
  };
};

const getSizeStyles = ({ height }) => {
  switch (height) {
    case HEIGHT.small:
      return {
        height: "38px",
      };

    default:
      return {
        height: "42px",
      };
  }
};

export const Wrapper: FC = (props) => {
  return (
    <Flex
      {...props}
      sx={{
        flexDirection: "column",
        position: "relative",
      }}
    />
  );
};

export const Label: FC<LabelProps> = ({ children, ...props }) => {
  const { id, required } = props;
  return (
    <Text
      variant="body-small"
      sx={{
        mb: 2,
        ml: 2,
        fontWeight: "bold",
      }}
    >
      <label htmlFor={id}>{children}</label>
      {required ? (
        <span
          sx={{
            ml: 1,
            color: "crimson",
          }}
        >
          *
        </span>
      ) : (
        ""
      )}
    </Text>
  );
};

export const InputContainer: FC = (props) => {
  return (
    <div
      {...props}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
};

export const Before: FC<BeforeAndAfterProps> = (props) => {
  const { error, warning, success, variant, height, isFocused, children, ..._rest } = props;
  const borderStyle = getBorderStyles({ error, warning, success, variant, isFocused });
  const sizeStyle = getSizeStyles({ height });
  return (
    <div
      sx={{
        boxSizing: "border-box",
        background: "whitesmoke",
        padding: "0 2px 0 8px",
        display: "flex",
        alignItems: "center",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRight: "none",
        borderTopLeftRadius: "8px",
        borderBottomLeftRadius: "8px",
        ...borderStyle,
        ...sizeStyle,
      }}
    >
      {children}
    </div>
  );
};

export const After: FC<BeforeAndAfterProps> = (props) => {
  const { error, warning, success, variant, height, isFocused, children, ..._rest } = props;

  const borderStyle = getBorderStyles({ error, warning, success, variant, isFocused });
  const sizeStyle = getSizeStyles({ height });
  return (
    <div
      sx={{
        boxSizing: "border-box",
        background: "whitesmoke",
        padding: "0 8px 0 2px",
        display: "flex",
        alignItems: "center",
        borderWidth: "1px",
        borderStyle: "solid",
        borderLeft: "none",
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
        ...borderStyle,
        ...sizeStyle,
      }}
    >
      {children}
    </div>
  );
};
export const StyledInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    error,
    warning,
    success,
    height = HEIGHT.default,
    variant = VARIANT.bordered,
    startEnhancer,
    endEnhancer,
    isFocused,
    ...rest
  } = props;

  const borderStyle = getBorderStyles({ error, warning, success, variant, isFocused });
  const sizeStyle = getSizeStyles({ height });

  return (
    <input
      ref={ref}
      {...rest}
      sx={{
        boxSizing: "border-box",
        display: "block",
        margin: "0",
        width: "100%",
        background: "whitesmoke",
        fontSize: height === "default" ? "15px" : "13px",
        lineHeight: "18px",
        padding: startEnhancer ? "8px 4px" : "8px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderTopLeftRadius: startEnhancer ? 0 : "8px",
        borderBottomLeftRadius: startEnhancer ? 0 : "8px",
        borderTopRightRadius: endEnhancer ? 0 : "8px",
        borderBottomRightRadius: endEnhancer ? 0 : "8px",
        borderLeft: startEnhancer && "none",
        borderRight: endEnhancer && "none",
        ...borderStyle,
        ...sizeStyle,
        ":focus": {
          outline: "none",
          borderColor: "slategray",
        },
      }}
    />
  );
});
